.card-pops{
    opacity: 0.8;

}
.card-pops:hover{
    /* border: 8px solid black; */
    opacity: 1;
    box-shadow: 5px 5px 5px 5px #888888;
    color: #4941A7;
 }
 .content{
    flex: 1 0 auto;
 }
