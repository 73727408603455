.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: flex;
  /* width: 100%; */
  border-color: #f0f0f0;
  border-radius: 5px;
}
th {
  background-color: #4941a7;
  /* opacity: 80%; */
  color: white;
}
.rowSelected {
  background-color: rgb(126, 245, 156);
}
body {
  overflow-x: hidden;
}